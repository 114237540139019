<template>
    <el-row class="my-order">
        <div class="order-title">云市场订单</div>
        <div class="order-header">
            <div class="order-status">
                <!--                <div class="status-item">全部订单</div>-->
                <!--                <div class="status-item">待付款</div>-->
                <!--                <div class="status-item">待发货</div>-->
                <!--                <div class="status-item">已发货</div>-->
                <!--                <div class="status-item">已完成</div>-->
                <el-tabs v-model="orderStatus" @tab-click="statusChange">
                    <el-tab-pane name="-1" label="全部订单"></el-tab-pane>
                    <el-tab-pane name="0" label="待付款"></el-tab-pane>
                    <el-tab-pane name="1" label="待发货"></el-tab-pane>
                    <el-tab-pane name="2" label="已发货"></el-tab-pane>
                    <el-tab-pane name="3" label="已完成"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="order-condition">
                <el-input v-model="keywords" placeholder="订单号" @keyup.enter.native="page.pageNo = 1, listOrder()">
                    <el-button slot="append" icon="el-icon-search" class="condition-search" @click="page.pageNo = 1, listOrder()"></el-button>
                </el-input>
            </div>
        </div>
        <div>
            <div>
                <el-table :data="orderList" style="width: 100%" v-loading="loading">
                    <el-table-column prop="orderNo" label="订单号"></el-table-column>
                    <el-table-column prop="orderStatus" label="订单状态">
                        <template slot-scope="scope">
                            <div class="table-status" v-if="scope.row.status === 0">
                                <div :style="{'background-color': getColor('notPay')}">&nbsp;</div>
                                待付款
                            </div>
                            <div class="table-status" v-if="scope.row.status === 1">
                                <div :style="{'background-color': getColor('isPay')}">&nbsp;</div>
                                待发货
                            </div>
                            <div class="table-status" v-if="scope.row.status === 2">
                                <div :style="{'background-color': getColor('isFaHuo')}">&nbsp;</div>
                                已发货
                            </div>
                            <div class="table-status" v-if="scope.row.status === 3">
                                <div :style="{'background-color': getColor('isQianShou')}">&nbsp;</div>
                                已完成
                            </div>
                            <div class="table-status" v-if="scope.row.status === 5">
                                <div :style="{'background-color': getColor('cancelOrder')}">&nbsp;</div>
                                取消交易
                            </div>
                            <div class="table-status" v-if="scope.row.status === 6">
                                <div :style="{'background-color': getColor('returnApply')}">&nbsp;</div>
                                退货申请
                            </div>
                            <div class="table-status" v-if="scope.row.status === 7">
                                <div :style="{'background-color': getColor('returnAudit')}">&nbsp;</div>
                                退货审核
                            </div>
                            <div class="table-status" v-if="scope.row.status === 8">
                                <div :style="{'background-color': getColor('returning')}">&nbsp;</div>
                                退货中
                            </div>
                            <div class="table-status" v-if="scope.row.status === -5">
                                <div :style="{'background-color': getColor('isReturn')}">&nbsp;</div>
                                已退货
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="subject" label="商品名称"></el-table-column>
                    <el-table-column prop="type" label="商品类别">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 1">云市场</span>
                            <span v-if="scope.row.type === 2">云产品</span>
                            <span v-if="scope.row.type === 3">解决方案</span>
                            <span v-if="scope.row.type === 4">软件商城</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="服务开通状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.state == 0">不启用</span>
                            <span v-if="scope.row.state == 1">等待开通服务</span>
                            <span v-if="scope.row.state == 2">服务开通失败</span>
                            <span v-if="scope.row.state == 3">服务开通成功</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="number" label="数量">
                        <template slot-scope="scope">
                            <span>*</span>
                            <span>{{ scope.row.number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="订单价格"></el-table-column>
                    <el-table-column prop="returnSupport" label="支持退货">
                        <template slot-scope="scope">
                            <span>{{ scope.row.returnSupport === 0 ? '支持' : '不支持' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentTime" label="交易时间" width="160"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link">
                                    <span class="table-handle">···</span>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="orderCommentDrawer(scope.row)">评论
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="orderInvoiceDrawer(scope.row)">申请开票
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="orderApplyContract(scope.row)">申请合同
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="goOrderInfo(scope.row)">订单详情</el-dropdown-item>
                                    <el-dropdown-item @click.native="orderRefund(scope.row)">退款</el-dropdown-item>
                                    <el-dropdown-item @click.native="orderRenewal(scope.row)">续费</el-dropdown-item>
                                    <el-dropdown-item @click.native="removeOrder(scope.row)">删除订单</el-dropdown-item>
                                    <el-dropdown-item @click.native="orderPay(scope.row)">立即支付</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="order-page">
                <el-pagination
                     background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="page.pageNo"
                     :page-sizes="[5, 10, 15, 20]"
                     :page-size="page.pageSize"
                     layout="total, prev, pager, next, sizes, jumper"
                     :total="page.total">
                </el-pagination>
            </div>
        </div>

        <!-- 评论-->
        <div>
            <el-drawer
                 title="订单评论"
                 :visible.sync="commentDrawer"
                 direction="rtl"
                 class="comment-drawer"
                 :close-on-click-modal="false"
                 :destroy-on-close="true"
                 :before-close="handleClose">
                <el-form
                     :model="commentForm"
                     :rules="commentRules"
                     ref="commentRef"
                >
                    <el-form-item label="内容" prop="content" label-width="80px">
                        <el-input type="textarea" v-model="commentForm.content" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="上传图片" prop="imgUrl" label-width="80px">
                        <el-upload
                             class="avatar-uploader"
                             action=""
                             :show-file-list="false"
                             :headers="uploadHeaders"
                             :http-request="upload"
                             :on-success="commentUploadSuccess"
                             :before-upload="beforeCommentUpload">
                            <img v-if="commentForm.imgUrl" :src="commentForm.imgUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer">
                    <el-button class="footer-button1" type="danger" @click="submitComment">提交</el-button>
                    <el-button class="footer-button2" @click="commentDrawer = false">取消</el-button>
                </div>
            </el-drawer>
        </div>

        <!--申请开票 -->
        <div>
            <el-drawer
                 title="申请开票"
                 :visible.sync="applyInvoiceDrawer"
                 direction="rtl"
                 :close-on-click-modal="false"
                 :destroy-on-close="true"
                 :before-close="handleClose">
                <el-form :model="invoiceForm" :rules="invoiceRules" ref="invoiceRef">
                    <el-form-item label="发票类型" prop="invoiceType" label-width="80px">
                        <el-select v-model="invoiceForm.invoiceType" clearable filterable>
                            <el-option
                                 v-for="(item,idx) in invoiceTypes"
                                 :key="idx"
                                 :value="item.dictKey"
                                 :label="item.dictValue"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收件地址" prop="address" label-width="80px">
                        <el-select
                             v-model="invoiceForm.address"
                             clearable
                             filterable
                             ref="invoiceAddress"
                             :disabled="invoiceForm.invoiceType === '2' || invoiceForm.invoiceType === '0'">
                            <el-option
                                 v-for="(item,idx) in invoiceAddress"
                                 :key="idx"
                                 :value="item.addressId"
                                 :label="item.detailedAddress"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email" label-width="80px">
                        <el-input v-model="invoiceForm.email" placeholder="请输入邮箱..."
                                  :disabled="invoiceForm.invoiceType === '2' || invoiceForm.invoiceType === '1'"></el-input>
                    </el-form-item>
                    <el-form-item label="发票抬头" prop="invoicePrefix">
                        <el-radio-group v-model="invoiceForm.invoicePrefix">
                            <el-radio
                                 v-for="(item,idx) in riseList"
                                 :key="idx"
                                 :label="item.dictKey"
                            >{{ item.dictValue }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="金额" prop="price" label-width="80px">
                        <el-input v-model="invoiceForm.price" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="企业税号" prop="companyTaxNum" label-width="80px">
                        <el-input v-model="invoiceForm.companyTaxNum"
                                  :disabled="invoiceForm.invoicePrefix === '0'"></el-input>
                    </el-form-item>
                    <el-form-item label="银行名称" prop="bankName" label-width="80px">
                        <el-input v-model="invoiceForm.bankName"
                                  :disabled="invoiceForm.invoicePrefix === '0'"></el-input>
                    </el-form-item>
                    <el-form-item label="银行账号" prop="bankAccount" label-width="80px">
                        <el-input v-model="invoiceForm.bankAccount"
                                  :disabled="invoiceForm.invoicePrefix === '0'"></el-input>
                    </el-form-item>
                    <el-form-item label="注册地址" prop="registryAddress" label-width="80px">
                        <el-input v-model="invoiceForm.registryAddress"
                                  :disabled="invoiceForm.invoicePrefix === '0'"></el-input>
                    </el-form-item>
                    <el-form-item label="注册电话" prop="registryPhone" label-width="80px">
                        <el-input v-model.number="invoiceForm.registryPhone"
                                  :disabled="invoiceForm.invoicePrefix === '0'"></el-input>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer">
                    <el-button class="footer-button1" type="danger" @click="submitInvoice">提交</el-button>
                    <el-button class="footer-button2" @click="applyInvoiceDrawer = false">取消</el-button>
                </div>
            </el-drawer>
        </div>

        <!--申请合同 -->
        <div>
            <el-drawer
                 title="申请合同"
                 :visible.sync="applyContractDrawer"
                 direction="rtl"
                 :close-on-click-modal="false"
                 :destroy-on-close="true"
                 :before-close="handleClose">
                <el-form :model="contractForm" :rules="contractRules" ref="contractRef">
                    <el-form-item label="合同类型" prop="contractType" label-width="80px">
                        <el-select v-model="contractForm.contractType" clearable filterable>
                            <el-option
                                 v-for="(item,idx) in contractTypes"
                                 :key="idx"
                                 :value="item.dictKey"
                                 :label="item.dictValue"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收件地址" prop="address" label-width="80px">
                        <el-select
                             v-model="contractForm.address"
                             clearable
                             filterable
                             ref="contractAddress"
                             :disabled="contractForm.contractType === '2'">
                            <el-option
                                 v-for="(item,idx) in invoiceAddress"
                                 :key="idx"
                                 :value="item.addressId"
                                 :label="item.detailedAddress"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合同标题" prop="contractTitle" label-width="80px">
                        <el-input v-model="contractForm.contractTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="startTime" label-width="80px">
                        <el-date-picker v-model="contractForm.startTime" type="date">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间" prop="endTime" label-width="80px">
                        <el-date-picker v-model="contractForm.endTime" type="date">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="完成时间" prop="finishTime" label-width="80px">
                        <el-date-picker v-model="contractForm.finishTime" type="date">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer">
                    <el-button class="footer-button1" type="danger" @click="submitContract">提交</el-button>
                    <el-button class="footer-button2" @click="applyInvoiceDrawer = false">取消</el-button>
                </div>
            </el-drawer>
        </div>


    </el-row>
</template>

<script>
import api from '@/api/buyer/cloudOrder.js'

export default {
    name: "cloudOrders",
    data() {
        return {
          loading: true,
            keywords: '',
            orderStatus: '-1',
            orderList: [],
            page: {
                pageNo: 1,
                pageSize: 5,
                total: 0
            },
            commentDrawer: false,   //评论
            applyContractDrawer: false,    //申请合同
            applyInvoiceDrawer: false,    //申请开票
            orderInfo: {},
            commentForm: {
                content: '',
                imgUrl: '',
            },
            commentRules: {},
            //申请发票表单
            invoiceForm: {
                invoiceType: '',    //发票类型
                address: '',    //收件地址
                email: '',  //邮箱
                invoicePrefix: '',  //发票抬头
                price: 0,   //金额
                companyTaxNum: '',  //企业税号
                bankName: '',   //银行名称
                bankAccount: '',    //银行账号
                registryAddress: '',    //注册地址
                registryPhone: '',  //注册电话
            },
            invoiceTypes: [],   //发票类型
            invoiceAddress: [], //收件地址
            invoiceRules: {
                invoiceType: [{required: true, message: '请选择发票类型', trigger: 'change'}],
                address: [{
                    required: false, trigger: 'change', validator: (rule, val, cb) => {
                        let form = this.invoiceForm;
                        if (form.invoiceType === '1' && !form.address) {
                            cb(new Error('选择纸质发票，地址不能为空'));
                            return;
                        }
                        cb();
                    }
                }],
                email: [{
                    required: false, trigger: 'change', validator: (rule, val, cb) => {
                        let form = this.invoiceForm;
                        if (form.invoiceType === '0') {
                            if (!form.email) {
                                cb(new Error('选择电子发票，邮箱不能为空'));
                                return;
                            }
                        }
                        cb();
                    }
                }],
                invoicePrefix: [{required: true, message: '请选择发票抬头', trigger: 'change'}],
            },
            //申请合同表单
            contractForm: {
                contractType: '',   //合同类型
                address: '',    //收件地址
                contractTitle: '',  //合同标题
                startTime: '',  //开始时间
                endTime: '',    //结束时间
                finishTime: '', //完成时间
            },
            contractTypes: [],
            contractRules: {
                contractType: [{required: true, message: '请选择合同类型', trigger: 'change'}],
                address: [{required: false, validator: (rule, val, cb) => {
                        let form = this.contractForm;
                        if (form.contractType === '1') {
                            if (!form.address) {
                                cb(new Error('选择邮寄时，地址不能为空'));
                                return;
                            }
                        }
                        cb();
                }}],
                contractTitle: [{required: true, message: '请输入合同标题', trigger: 'change'}],
                startTime: [{required: true, message: '请选择开始时间', trigger: 'change'}],
                endTime: [{required: true, message: '请选择结束时间', trigger: 'change'}],
                finishTime: [{required: true, message: '请选择完成时间', trigger: 'change'}],
            },
            uploadImg: api.uploadImg,
            uploadHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            },
            riseList: [], //发票抬头
            colorList: [
                {key: 'isPay', color: '#e36ad7'},   //已支付
                {key: 'notPay', color: '#cd7169'},  //未支付
                {key: 'isFaHuo', color: '#8463f3'}, //已发货
                {key: 'isQianShou', color: '#8cf2be'},  //已签收
                {key: 'cancelOrder', color: '#969696'}, //取消订单
                {key: 'returnApply', color: '#e9be73'}, //退货申请
                {key: 'returnAudit', color: '#db8c4e'}, //退货审核
                {key: 'returning', color: '#e88368'},   //退货中
                {key: 'auditOut', color: '#ed6560'},    //审核不通过
                {key: 'isReturn', color: '#8ef4b4'},    //已退货
            ],
        }
    },
    mounted() {
      document.body.style.overflow = 'hidden';
        this.listOrder();
        this.getDictData();
        this.listAddressByOrder();
    },
    methods: {
        getColor(key) {
            let color = 'black';
            this.colorList.forEach(item => {
                if (item.key === key) {
                    color = item.color;
                }
            })
            return color;
        },
        //获取地址数据
        listAddressByOrder() {
            api.getAddressListByOrder({}).then(res => {
              this.timer = setTimeout(()=>{   //设置延迟执行
                this.loading = false
              },300)
                this.invoiceAddress = res.data;
            })
        },
        //基础数据
        getDictData() {
            let param = ['invoice_type', 'invoice_rise', 'contract_type'];
            api.getCacheDictData(param).then(res => {
                let data = res.data;
              this.timer = setTimeout(()=>{   //设置延迟执行
                this.loading = false
              },300)
                if (data) {
                    this.invoiceTypes = data.invoice_type;
                    this.riseList = data.invoice_rise;
                    this.contractTypes = data.contract_type;
                }
            })
        },
        //评论提交
        submitComment() {
            this.$refs.commentRef.validate(valid => {
                if (valid) {
                    let form = this.commentForm;
                    api.addCommnet({
                        content: form.content,
                        orderId: this.orderInfo.orderId,
                        urlList: form.imgUrl ? [form.imgUrl] : []
                    }).then(res => {
                        if (res.code !== 200) {
                            this.buyerMsg(res.message, 'error');
                            return;
                        }
                        this.buyerMsg('评论成功');
                        this.$refs.commentRef.resetFields();
                        this.commentDrawer = false;
                        this.page.pageNo = 1;
                        this.listOrder();
                    })
                }
            })
        },
        //申请开票提交
        submitInvoice() {
            this.$refs.invoiceRef.validate(valid => {
                if (valid) {
                    let form = this.invoiceForm;
                    api.applyInvoice({
                        type: parseInt(form.invoiceType),
                        addressId: form.address,
                        express: this.$refs.invoiceAddress.selected.label,
                        email: form.email,
                        rise: parseInt(form.invoicePrefix),
                        cost: form.price,
                        dutyNum: form.companyTaxNum,
                        bankName: form.bankName,
                        bankNum: form.bankAccount,
                        regAddress: form.registryAddress,
                        regPhone: form.registryPhone,
                        orderId: this.orderInfo.orderId,
                    }).then(res => {
                        if (res.code != 200) {
                            return this.buyerMsg(res.message, 'error');
                        }
                        this.buyerMsg('申请成功');
                        this.$refs.invoiceRef.resetFields();
                        this.applyInvoiceDrawer = false;
                        this.page.pageNo = 1;
                        this.listOrder();
                    })
                }
            })
        },
        //申请合同提交
        submitContract() {
            this.$refs.contractRef.validate(valid => {
                if (valid) {
                    let form = this.contractForm;
                    api.applyContract({
                        orderId: this.orderInfo.orderId,
                        type: parseInt(form.contractType),
                        addressId: form.address,
                        title: form.contractTitle,
                        takeStartDate: form.startTime,
                        takeEndDate: form.endTime,
                        successDate: form.finishTime
                    }).then(res => {
                        if(res.code != 200) {
                            return this.buyerMsg(res.message, 'error');
                        }
                        this.buyerMsg('申请成功');
                        this.$refs.contractRef.resetFields();
                        this.applyContractDrawer = false;
                        this.page.pageNo = 1;
                        this.listOrder();
                    })
                }
            })
        },
        //评论
        orderCommentDrawer(val) {
            if (val.status === 0) {
                return this.buyerMsg('未付款的订单不能评论', 'warning');
            }
            if (val.isComment === 1) {
                return this.buyerMsg('请不要重复评论', 'warning');
            }
            this.orderInfo = val;
            this.commentDrawer = true;
        },
        //申请开票
        orderInvoiceDrawer(val) {
            if (val.status === 0) {
                return this.buyerMsg('未付款的订单不能申请开票', 'warning');
            }
            if (val.isInvoice == 1) {
                return this.buyerMsg('请不要重复申请开票', 'warning');
            }
            this.orderInfo = val;
            this.applyInvoiceDrawer = true;
        },
        //申请合同
        orderApplyContract(val) {
            if (val.status === 0) {
                return this.buyerMsg('未付款的订单不能申请合同', 'warning');
            }
            if(val.isContract == 1) {
                return this.buyerMsg('请不要重复申请合同', 'warning');
            }
            this.orderInfo = val;
            this.applyContractDrawer = true;
        },
        //订单详情
        goOrderInfo(val) {
            sessionStorage.setItem('orderId', val.orderId);
            sessionStorage.setItem('shopId', val.shopId);
            this.$router.push('/buyer/orderInfo');
        },
        //退款
        orderRefund(val) {
            if(val.returnSupport !== 0) {
                return this.buyerMsg('该订单不支持退款', 'warning');
            }
            if(val.status === 0) {
                return this.buyerMsg('未付款的订单不能退款', 'warning');
            }
            if(val.status === 6) {
                return this.buyerMsg('请不要重复发起退款', 'warning');
            }
            let payTime = val.paymentTime;
            if(payTime) {
                let diffDay = this.$moment().diff(this.$moment(payTime), 'day')
                if(diffDay > 5) {
                    return this.buyerMsg('订单超过五天不能退款', 'warning');
                }
            }

            api.applyRefund({
                orderId: val.orderId
            }).then(res => {
                if(res.code != 200) {
                    return this.buyerMsg(res.message, 'error');
                }
                this.buyerMsg('申请退款成功');
                this.page.pageNo = 1;
                this.listOrder();
            })
        },
        //订单续费
        orderRenewal(val) {
            if(val.state !== '3') {
                return this.$message.warning('请先购买此商品');
            }
            document.body.style.overflow = 'visible'
            this.$router.push({
                path: '/cloudMarket',
                query: {
                    productId: val.objectId,
                    orderType: 2,
                    oldOrderId: val.orderId,
                    oldOrderNo: val.orderNo,
                    domianName: val.domianName
                }
            });
        },
        //删除订单
        removeOrder(val) {
            if(val.status != 0) {
                return this.buyerMsg('只能删除未付款的订单', 'warning');
            }
            this.$confirm('确认要删除吗？', '是否删除确认',{customClass: 'customClass'}).then(() => {
                api.removeOrder({
                    orderId: val.orderId
                }).then(res => {
                    if(res.code != 200) {
                        return this.buyerMsg(res.message, 'error');
                    }
                    this.buyerMsg('删除成功');
                    this.listOrder();
                })
            })
        },
        //立即支付
        orderPay(val) {
            if(val.status != 0) {
                return this.buyerMsg('只能未付款的订单才能支付', 'warning');
            }else {
              // this.$router.push({path:'/pay', query: {orderId: val.orderId, payType: 1}})
              document.body.style.overflow = 'visible'
              this.$router.push({path: '/pay',query:{orderId: val.orderId}});
            }
        },
        statusChange() {
            this.page.pagenNo = 1;
            this.listOrder();
        },
        listOrder() {
            api.listOrders({
                pageNo: this.page.pageNo,
                pageSize: this.page.pageSize,
                status: this.orderStatus == -1 ? '' : parseInt(this.orderStatus),
                orderNo: this.keywords
            }).then(res => {
              this.timer = setTimeout(()=>{   //设置延迟执行
                this.loading = false
              },300)
                if (res.data) {
                    this.orderList = res.data.records;
                    this.page.total = res.data.total;
                }

            })
        },
        commentUploadSuccess(res, file) {
            this.commentForm.imgUrl = URL.createObjectURL(file.raw);
        },
        upload(param) {
            let val = new FormData();
            val.append('file', param.file);
            api.uploadImg(val).then(res => {
                if (res.code != 200) {
                    this.buyerMsg(res.message, 'error');
                    return;
                }
                this.buyerMsg('上传成功');
                this.commentForm.imgUrl = res.data;
            })
        },
        beforeCommentUpload(file) {
            let suffixLIst = ['jpg', 'jpeg', 'png', 'bmp', 'gif']
            var suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (suffixLIst.indexOf(suffix) === -1) {
                this.buyerMsg('只能上传jpg,jpeg,png,bmp,gif文件', 'error')
                return false;
            }
            if (!isLt2M) {
                this.buyerMsg('上传头像图片大小不能超过 2MB!', 'error');
                return false;
            }
            return true;
        },
        handleClose(done) {
            done();
        },
        handleSizeChange(val) {
            this.page.pageSize = val;
            this.listOrder();
        },
        handleCurrentChange(val) {
            this.page.pageNo = val;
            this.listOrder();
        }
    }
}
</script>
<style>
.el-button--primary  {
  background: #f65d59 !important ;
  border-color: #f65d59 !important;
}
</style>
<style scoped>
.my-order {
    width: 100%;
    font-size: 14px;
    padding: 1% 0% 0% 3%;
}

.order-title {
    color: #f6837a;
    font-size: 24px;
}

.order-header {
    display: flex;
}

.order-status {
    display: flex;
    margin-top: 2%;
    width: 80%;
}

.status-item {
    margin-right: 2%;
    cursor: pointer;
}

.status-item:hover {
    font-weight: bold;
    color: #F46E64;
}

.status-item-active {
    font-weight: bold;
    color: #F46E64;
}

.order-condition {
    margin-top: 1.5%;
}

.table-status {
    display: flex;
}

.table-status div {
    width: 8px;
    height: 8px;
    margin: 5% 5% 0% 0%;
    border-radius: 50%;
}

.fukuan {
    background: #FF1400;
}

.fahuo {
    background: #FF8B00;
}

.is-fahuo {
    background: #A900FF;
}

.wancheng {
    background: #64BA21;
}

.condition-search {
    color: #F46E64;
}

.order-page {
    margin-top: 2%;
    float: right;
    margin-right: 4%;
}

.table-handle {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

/deep/ .el-table .el-table__cell {
    padding: 30px 0;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: none;
    color: #F46E64;
    border: 1px solid #F46E64;
    border-radius: 30%;
}

/deep/ .el-pagination.is-background .btn-next {
    border: 1px solid;
    color: #F46E64;
    border-radius: 30%;
}

/deep/ .el-pager li {
    border: 1px solid #B3B3B3;
    border-radius: 30% !important;
}

/deep/ .el-pagination.is-background .btn-prev {
    background: none;
    color: #F46E64;
    border: 1px solid #F46E64;
    border-radius: 30%;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #F46E64;
}

.el-dropdown-menu {
    overflow: scroll;
    max-height: 200px;
    overflow-x: hidden;
}

/deep/ .el-drawer__header {
    font-size: 16px;
}

/deep/ .el-textarea {
    width: 84%;
}

/*/deep/ .el-input {*/
/*    width: 80%;*/
/*}*/
/deep/ .el-textarea__inner {
    min-height: 80px !important;
}

/deep/ .el-drawer__body {
    padding: 3%;
}

/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.drawer-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 10%;
    text-align: right;
    width: 100%;
    border-top: 1px solid #e8e8e8;
}

.footer-button1 {
    margin-right: 6%;
}

.footer-button2 {
    margin: 5% 10% 0% 0%;
}

.small-label {
    /*margin-left: 5%;*/
}

/deep/ .el-select {
    width: 100%;
}

/deep/ .order-status .el-tabs__item:hover,
/deep/ .order-status .el-tabs__item.is-active,
/deep/ .order-status .el-icon-search:before {
    color: #F76F66;
}

/deep/ .el-tabs__nav-wrap::after {
    position: static !important;
}

/deep/ .el-tabs__active-bar {
    display: none;
}

/deep/ .el-select-dropdown__item.selected {
    color: #F76F66;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #F76F66;
}
</style>
