import {get,post} from "@/utils/axios";

//订单列表
const listOrders = p => get("/api-seller/apiConsoleGoods/getOrderListByConsole", p);

//添加评论
const addCommnet = p => post("/api-seller/apiconsole/discuss/syncCommentAdd", p);

//上传图片
const uploadImg = p => post('/api-upload/img/uploadImg', p);

//获取基础数据
const getCacheDictData = p => post("/api-common/dict/getCacheDictData", p);

//地址数据
const getAddressListByOrder = p => get("/api-seller/address/getAddressListByOrder", p);

//申请开票
const applyInvoice = p => post("/api-seller/apiconsole/invoice/syncInvoiceApply", p);

//申请合同
const applyContract = p => post("/api-seller/apiconsole/contract/syncContractApply", p);

//删除订单
const removeOrder = p => post("/api-seller/apiConsoleGoods/deleteOrder", p);

//退款
const applyRefund = p => post("/api-seller/apiConsoleGoods/syncOrderRefund", p);

export default {
    listOrders,
    addCommnet,
    uploadImg,
    getCacheDictData,
    getAddressListByOrder,
    applyInvoice,
    applyContract,
    removeOrder,
    applyRefund,
}